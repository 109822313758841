<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" id="newsletter">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div
              class="align-items-center mb-4"
              style="display: grid; grid-template-columns: repeat(7, auto)"
            >
              <div>
                <div
                  class="form-inline navbar-search"
                  style="justify-content: start"
                >
                  <div class="input-group">
                    <input
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search..."
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      v-model="filter"
                      v-on:keyup="search"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="searchFor">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr 1fr;
                  grid-gap: 10px;
                "
                class="ml-5"
              >
                <div class="">
                  <!-- <div style="width: 30%"> -->
                  <b>Member Status Filter:</b>
                  <!-- </div> -->
                  <b-form-select
                    id="verified"
                    size="sm"
                    v-model="member_status_fil"
                    value-field="value"
                    text-field="text"
                    @change="filterMemberStatus(member_status_fil)"
                    :options="verified_options"
                  >
                  </b-form-select>
                </div>
                <div class="">
                  <!-- <div style="width: 30%"> -->
                  <b>Speciality Filter:</b>
                  <!-- </div> -->
                  <b-form-select
                    id="verified"
                    size="sm"
                    v-model="speciality"
                    value-field="title"
                    text-field="title"
                    @change="filterSpeciality(speciality)"
                    :options="allSpecialityWithAll"
                  >
                  </b-form-select>
                </div>
                <div class="">
                  <b class="mr-2">Country Filter:</b>
                  <b-form-select
                    id="verified"
                    size="sm"
                    v-model="country"
                    value-field="name"
                    text-field="name"
                    @change="filterCountry(country)"
                    :options="getCountry"
                  >
                  </b-form-select>
                </div>
                <div class="">
                  <!-- <div style="width: 40%"> -->
                  <b>State Filter:</b>
                  <!-- </div> -->
                  <b-form-select
                    id="verified"
                    size="sm"
                    v-model="state"
                    value-field="state"
                    text-field="state"
                    @change="filterState(state)"
                    :options="getState"
                  >
                  </b-form-select>
                </div>
                <div class="">
                  <b class="mr-2">City Filter:</b>
                  <b-form-select
                    id="verified"
                    size="sm"
                    v-model="city"
                    value-field="city"
                    text-field="city"
                    @change="filterCity(city)"
                    :options="allCity"
                  >
                  </b-form-select>
                </div>
                <div class="">
                  <!-- <div style="width: 30%"> -->
                  <b>Member Type Filter:</b>
                  <!-- </div> -->
                  <b-form-select
                    id="verified"
                    size="sm"
                    v-model="member_type_fil"
                    value-field="value"
                    text-field="text"
                    @change="filterMemberTypeStatus(member_type_fil)"
                    :options="member_type_options"
                  >
                  </b-form-select>
                </div>
              </div>
            </div>
            <div class="row align-items-center mb-4">
              <div class="col-md-12">
                <div class="all-tabs">
                  <div
                    :class="activeTab == 'all' ? ' tab-options' : ''"
                    @click.prevent="fetchData('all')"
                    class="mx-2 opt"
                  >
                    <b-badge variant="warning">All</b-badge>
                  </div>
                  |
                  <div
                    :class="activeTab == 'member' ? ' tab-options' : ''"
                    @click.prevent="fetchData('member')"
                    class="mx-2 opt"
                  >
                    <b-badge variant="warning">Member</b-badge>
                  </div>
                  <div
                    :class="activeTab == 'non-member' ? ' tab-options' : ''"
                    @click.prevent="fetchData('non-member')"
                    class="mx-2 opt"
                  >
                    <b-badge style="background-color: rgb(132 100 182)"
                      >Non Member</b-badge
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive mb-0">
              <b-table
                responsive="sm"
                striped
                bordered
                :items="tableData.data"
                :per-page="0"
                :current-page="currentPage"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="table-bordered table-hover"
                :key="key"
              >
                <template v-slot:cell(internal_user)="row">
                  <img
                    src="/tick.svg"
                    height="20px"
                    style="color: red"
                    v-if="row.item.internal_user"
                  />
                  <img
                    src="/close.svg"
                    height="20px"
                    style="color: red"
                    v-else
                  />
                </template>
                <template v-slot:cell(is_verified)="row">
                  <span
                    class="badge badge-danger p-1"
                    v-if="row.item.is_verified == '0'"
                    >No Status</span
                  >
                  <span
                    class="badge badge-success p-1"
                    v-else-if="row.item.is_verified == '1'"
                    >Verified</span
                  >
                  <span
                    class="badge badge-warning p-1"
                    v-if="row.item.is_verified == '2'"
                    >Not Verified</span
                  >
                  <span
                    class="badge badge-primary p-1"
                    v-if="row.item.is_verified == '3'"
                    >Processing</span
                  >
                  <span
                    class="badge badge-info p-1"
                    v-if="row.item.is_verified == '5'"
                    >Not Valid User</span
                  >
                </template>
                <template
                  v-can="'edit-universal-member-list'"
                  v-slot:cell(edit)="row"
                >
                  <router-link
                    :to="{
                      name: 'edit-universal',
                      params: { id: row.item.id },
                    }"
                    v-if="row.item.member_tag == 'non-member'"
                  >
                    <i class="fa fa-edit edit-i"></i>
                  </router-link>
                  <span v-else>
                    <i class="fa fa-edit is_processed_edit"></i>
                  </span>
                </template>
                <template v-slot:cell(share)="row">
                  <template v-if="row.item.id">
                    <a
                      class="btn btn-social-circle btn-twitter"
                      @click="copyURL(row.item.speciality, row.item.id)"
                    >
                      <i class="fa fa-link"></i>
                    </a>
                  </template>
                </template>
                <template v-slot:cell(speciality)="row">
                  <span class="text-center">
                    <span
                      v-if="row.item.speciality"
                      class="badge badge-success p-1"
                      >{{ row.item.speciality }}</span
                    >
                    <span v-else>---</span>
                  </span>
                </template>
                <template v-slot:cell(member_tag)="row">
                  <span
                    v-if="row.item.member_tag == 'member'"
                    class="badge badge-primary p-1"
                    >Member</span
                  >
                  <span v-else class="badge badge-info p-1">Non Member</span>
                </template>
                <template v-slot:cell(member_type)="row">
                  <span
                    v-if="row.item.member_type"
                    class="badge badge-primary p-1"
                    >{{row.item.member_type}}</span
                  >
                  <span v-else class="badge badge-info p-1"> --- </span>
                </template>
                <template v-slot:cell(name)="row">
                  <span class="text-center">
                    <template
                      v-if="
                        (row.item.first_name != null &&
                          row.item.first_name != '') ||
                        (row.item.last_name != null && row.item.last_name != '')
                      "
                    >
                      <span class="email-word-break"
                        >{{ row.item.first_name }}
                        {{ row.item.last_name }}</span
                      >
                    </template>
                    <template
                      v-else-if="
                        row.item.first_name == 'null' ||
                        row.item.last_name == 'null'
                      "
                    >
                      <span class="email-word-break">---</span>
                    </template>
                    <template
                      v-else-if="
                        row.item.first_name == '' || row.item.last_name == ''
                      "
                    >
                      <span>---</span>
                    </template>
                    <template
                      v-else-if="
                        row.item.first_name == null ||
                        row.item.last_name == null
                      "
                    >
                      <span>---</span>
                    </template>
                    <template v-else>
                      <span>---</span>
                    </template>
                  </span>
                </template>
                <template v-slot:cell(mobile_number)="row">
                  <template v-if="row.item.mobile_number">
                    <span class="email-word-break text-center">{{
                      obfuscatePhone(row.item.mobile_number)
                    }}</span>
                  </template>
                  <template
                    v-else-if="
                      row.item.mobile_number === 'null' ||
                      row.item.mobile_number === null
                    "
                  >
                    <span>---</span>
                  </template>
                  <template v-else>
                    <span>---</span>
                  </template>
                </template>
                <template v-slot:cell(email)="row">
                  <template
                    v-if="row.item.email != null && row.item.email != ''"
                  >
                    <span class="email-word-break text-center">{{
                      obfuscateEmail(row.item.email)
                    }}</span>
                  </template>
                  <template v-else>
                    <span>---</span>
                  </template>
                </template>
                <template v-slot:cell(state)="row">
                  <span v-if="row.item.state" class="text-center">{{
                    row.item.state
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(city)="row">
                  <span v-if="row.item.city" class="text-center">{{
                    row.item.city
                  }}</span>
                  <span v-else>---</span>
                </template>
              </b-table>
              <b-modal id="myModal">
                <div>
                  <img
                    :src="image"
                    class="transition"
                    v-bind:style="{ transform: `rotate(${deg}deg)` }"
                    alt="refresh-icon-btn"
                  />
                </div>
                <template v-slot:modal-footer class="p-0">
                  <b-button @click="rotation">Rotate</b-button>
                </template>
              </b-modal>
              <b-modal id="noteModal" size="lg">
                <h2 class="text-center py-3">Notes</h2>

                <div class="container py-2 mt-4 mb-4">
                  <div
                    class="row"
                    v-for="message in messages"
                    :key="message.id"
                  >
                    <div
                      class="col-auto text-center flex-column d-none d-sm-flex"
                    >
                      <div class="row h-50">
                        <div class="col">&nbsp;</div>
                        <div class="col">&nbsp;</div>
                      </div>
                      <h5 class="m-2">
                        <span class="badge1 badge-pill bg-light border"
                          >&nbsp;</span
                        >
                      </h5>
                      <div class="row h-50">
                        <div class="col border-right">&nbsp;</div>
                        <div class="col">&nbsp;</div>
                      </div>
                    </div>
                    <div class="col py-2">
                      <div class="card">
                        <div class="card-body">
                          <div class="float-right text-muted">
                            {{ getSessionStart(message.created_at) }}
                          </div>
                          <h4 class="card-title">{{ message.mci_note }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <template v-slot:modal-footer class="p-0">
                  <b-input-group
                    @submit.prevent="sendMessage('out')"
                    id="person2-form"
                  >
                    <label for="person2-input">You : </label>
                    <b-input
                      v-model="message"
                      id="person2-input"
                      type="text"
                      placeholder="Type your message"
                    ></b-input>
                    <b-button @click="storeNotes(id, message)" type="submit"
                      >Send</b-button
                    >
                  </b-input-group>
                </template>
              </b-modal>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    ></b-pagination>
                  </ul>
                </div>
                <div class="form-inline navbar-search">
                  <span class="mr-2">Go to a Particular Paginated Page :</span>
                  <div class="input-group">
                    <b-form-input
                      id="case"
                      v-model="pageNo"
                      placeholder="Enter Page No"
                      class="in-width"
                    >
                    </b-form-input>
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="filterPage">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.transition {
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.more-colmns {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-gap: 10px;
}

.badge {
  padding: 8px 10px;
  font-size: 12px;
}
.badge1 {
  padding: 8px 10px;
  font-size: 7px;
}
.chat-area {
  /*   border: 1px solid #ccc; */
  background: white;
  height: 50vh;
  padding: 1em;
  overflow: auto;
  max-width: 350px;
  margin: 0 auto 2em auto;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.3);
}
.message-out {
  background: #407fff;
  color: white;
  margin-left: 50%;
  border-radius: 10px;
  padding: 0.5em;
}
</style>
<style lang="scss" scoped>
.tab-options {
  color: #3169aa !important;

  position: relative;

  &::after {
    content: "";
    position: absolute;
    border-bottom: 5px solid #0a0606 !important;
    left: 0;
    bottom: -3px;
    width: 100%;
  }
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import universal_mixin from "../../../mixins/ModuleJs/universal";

export default {
  mixins: [MixinRequest, universal_mixin],
  data() {
    return {
      title: "Universal Member List",
      mci_file: {},
      editPhoneNo: null,
      deg: 0,
      image: "",
      index: 0,
      isClickedPhone: false,
      isClickedEmail: false,
      isClickedName: false,
      isClickedMciNo: false,
      isClickedMciYear: false,
      isClickedMciReferrel: false,
      isClickedMciState: false,
      isClickedCallDoctor: false,
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  methods: {
    obfuscateEmail(email) {
      if (
        email != null &&
        email != "" &&
        email != "NA" &&
        email != "null" &&
        email != "NULL" &&
        email != "Null"
      ) {
        if (email.trim()) {
          return (
            email.trim().substring(0, 3) +
            "*".repeat(email.length - 5) +
            email.substring(email.length - 4)
          );
        }
      }
    },
    obfuscatePhone(phone) {
      if (
        phone != null &&
        phone != "" &&
        phone.length >= 7 &&
        phone != "NULL" &&
        phone != "null" &&
        phone != "Null" &&
        phone != "NA"
      ) {
        return (
          phone.trim().substring(0, 3) +
          "*".repeat(phone.length - 5) +
          phone.substring(phone.length - 2)
        );
      }
    },
    rotation() {
      this.deg += 90;
    },
    editPhone(member_id) {
      this.isClickedEmail = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = false;
      (this.isClickedMciReferrel = false), (this.isClickedMciState = false);
      this.isClickedCallDoctor = false;
      this.isClickedPhone = true;
      this.isClickedName = false;
      this.index = member_id;
    },
    editEmail(member_id) {
      this.isClickedPhone = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = false;
      this.isClickedMciReferrel = false;
      this.isClickedMciState = false;
      this.isClickedCallDoctor = false;
      this.isClickedEmail = true;
      this.isClickedName = false;
      this.index = member_id;
    },
    editMciNo(member_id) {
      this.isClickedPhone = false;
      this.isClickedEmail = false;
      this.isClickedMciState = false;
      this.isClickedCallDoctor = false;
      this.isClickedMciNo = true;
      this.isClickedMciYear = false;
      this.isClickedMciReferrel = false;
      this.isClickedName = false;
      this.index = member_id;
    },
    editMciState(member_id) {
      this.isClickedPhone = false;
      this.isClickedEmail = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = false;
      this.isClickedMciReferrel = false;
      this.isClickedCallDoctor = false;
      this.isClickedMciState = true;
      this.isClickedName = false;
      this.index = member_id;
    },
    editDigiMrId(member_id) {
      this.isClickedPhone = false;
      this.isClickedEmail = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = false;
      this.isClickedMciReferrel = false;
      this.isClickedMciState = false;
      this.isClickedCallDoctor = true;
      this.isClickedName = false;
      this.index = member_id;
    },
    sendInfo(image, image_path) {
      this.image = image_path + image;
      console.log(image);
      console.log(this.image);
    },
    sendInfoNote(val) {
      this.id = val;
      this.fetchNotes(this.id);
    },
    sendInfoQualification(id) {
      this.mid = id;
      console.log(id);
      console.log(this.mid);
    },
    editMciYear(member_id) {
      this.isClickedPhone = false;
      this.isClickedEmail = false;
      this.isClickedMciState = false;
      this.isClickedCallDoctor = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = true;
      this.isClickedMciReferrel = false;
      this.isClickedName = false;
      this.index = member_id;
    },
    editMciRefferrel(member_id) {
      this.isClickedPhone = false;
      this.isClickedEmail = false;
      this.isClickedMciState = false;
      this.isClickedCallDoctor = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = false;
      this.isClickedMciReferrel = true;
      this.isClickedName = false;
      this.index = member_id;
    },
    editName(member_id) {
      this.isClickedPhone = false;
      this.isClickedEmail = false;
      this.isClickedMciState = false;
      this.isClickedCallDoctor = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = false;
      this.isClickedMciReferrel = false;
      this.isClickedName = true;
      this.index = member_id;
    },
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>
